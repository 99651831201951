@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .shadow-header {
        box-shadow: 0 1px 4px rgba(30, 47, 90, 0.25);
    }
    .date-placeholder::before {
        width: 100%;
        content: attr(placeholder);
        color: #9ca2af;
    }
    .progression-bar {
        background: linear-gradient(90deg, #20cdff var(--p, 25%), #e5e7eb var(--p, 25%));
    }
    .button-arrow-left {
        svg path {
            fill: #00addf !important;
        }
    }
    .selected-option-qualified-lead {
        background-color: #dbf7ff !important;
        color: #005670 !important;
        border-color: #005670 !important;
    }
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Roboto !important;
}

#__next {
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
}

$primary-orange: #ff7650;
$primary-blue: #00addf;
$dark-blue: #101f44;
$dark-blue-2: #030c1d;
$gradient-1: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 209, 197, 1) 100%);
$gradient-2: linear-gradient(180deg, rgba(197, 236, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
$hero-gradient: linear-gradient(0deg, rgba(10, 28, 63, 0) 0%, rgba(16, 31, 68, 1) 100%);
$gradient-orange: linear-gradient(90deg, #ff7650 22.45%, #fefcd6 247.45%);
$radial-gradient: radial-gradient(at right center, #00aedf, #00202a);

@font-face {
    font-family: Roboto;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/circular/CircularStd-Black.ttf');
}

.debug,
.debug-red {
    outline: 2px solid red;
}
.debug-green {
    outline: 2px solid green;
}
.debug-blue {
    outline: 2px solid blue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, 'Circular Std', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue' !important;
}

.cli-blue {
    color: $primary-blue;
}

.bg-cli-blue {
    background: $primary-blue;
}

.cli-orange {
    color: $primary-orange;
}

.cli-darkblue {
    color: $dark-blue;
}

.bg-cli-darkblue-2 {
    background: $dark-blue-2;
}

.bg-cli-darkblue {
    background: $dark-blue;
}

.bg-cli-orange {
    background: $gradient-orange;
}

.bg-gradient-1 {
    background: $gradient-1;
}

.bg-gradient-2 {
    background: $gradient-2;
}

.bg-primary-orange {
    background: $primary-orange;
}

.bg-gradient-blue {
    background: linear-gradient(179.89deg, #0074df 0.1%, #00addf 53.21%, #70deff 103.1%);
}
.bg-gradient-orange {
    background: linear-gradient(180deg, #FF7650 20%, #FFAF50 80%, #FEC684 100%);
}

.bg-radial-gradient {
    background: radial-gradient(at center top, #00aedf, #00202a);
    @media (min-width: 768px) {
        background: radial-gradient(at right center, #00aedf, #00202a);
    }
}

.bg-radial-gradient-tl {
    background: radial-gradient(at top left, #00aedf, #00202a);
    @media (min-width: 768px) {
        background: radial-gradient(at top left, #00aedf, #00202a);
    }
}
.bg-inverted-radial-gradient {
    background: radial-gradient(at right bottom, #00202a, #00aedf);
    @media (min-width: 768px) {
        background: radial-gradient(at right bottom, #00202a, #00aedf);
    }
}

.text-blue-gradient {
    background: linear-gradient(179.89deg, #0074df 0.1%, #00addf 53.21%, #70deff 103.1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.licensePlateInput {
    background-image: url('/images/spain-distinctive.svg') !important;
    background-repeat: no-repeat !important;
    background-position: -2px !important;
    background-size: contain !important;
    padding-left: 3rem !important;
    letter-spacing: 1rem;
}

.bg-hero {
    background-image: url('/images/hero-bg-mobile.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::after {
        background: $hero-gradient;
        background: red;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        position: absolute;
    }
}

@media (min-width: 768px) {
    .bg-hero {
        background-image: url('/images/Hero-Bg.jpg');
    }
}

button.cta {
    border-radius: 12px;
    height: 56px;
    min-width: 240px;
    background: linear-gradient(90deg, #ff7650 22.45%, #fefcd6 247.45%);
    padding: 10px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: white;
    display: block;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.05);
        transition: all 0.1s ease-in-out;
    }
}

button.cta-blue {
    background-color: #00addf;
    @apply text-lavender-100;
}

.heading-1 {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: 'Roboto';
    font-size: 56px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.heading-2 {
    opacity: 1;
    color: rgba(16, 31, 68, 1);
    font-family: 'Roboto';
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 61px;
}

.heading-3 {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: 'Roboto';
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
}

.heading-4 {
    font-size: 24px;
}

.bg-girl-laughing {
    background-image: url('/images/chica-sonriendo.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 600px;
}

.chakra-form-control {
    min-height: 100px; // ?: to prevent layout shift when the error message is shown
}

.chakra-radio__label {
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 400;
}

.active-route {
    color: $primary-blue;
}

.team-icon path {
    stroke: rgb(143, 218, 255);
}

.gradient-blueSky {
    background: linear-gradient(359.8deg, #0074df 0.03%, #00addf 47.69%, #70deff 92.47%);
}

.gradient-sunrise {
    background: linear-gradient(180deg, #ff7650 0%, #ffaf50 50%, #fec684 100%);
    opacity: 0.75;
}

.text-gradient-blueSky {
    background: linear-gradient(179.89deg, #0074df 0.1%, #00addf 53.21%, #70deff 103.1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.teams-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: 'one one two two three three four four' '. five five six six seven seven .';

    & div:nth-child(1) {
        grid-area: one;
    }

    & div:nth-child(2) {
        grid-area: two;
    }

    & div:nth-child(3) {
        grid-area: three;
    }

    & div:nth-child(4) {
        grid-area: four;
    }

    & div:nth-child(5) {
        grid-area: five;
    }

    & div:nth-child(6) {
        grid-area: six;
    }

    & div:nth-child(7) {
        grid-area: seven;
    }

    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
}

.options-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: '. . one one . .' '. two two three three .';
    gap: 1rem;

    & div:nth-child(1) {
        grid-area: one;
    }

    & div:nth-child(2) {
        grid-area: two;
    }

    & div:nth-child(3) {
        grid-area: three;
    }

    @media (max-width: 1359px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & div:nth-child(n) {
            width: 100%;
            display: flex;
            flex: 1;
        }
    }
}

.heroCTA {
    @media (min-width: 1360px) {
        border-bottom-right-radius: 100px;
    }
}
